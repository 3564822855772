import React, { useState } from "react";

import { useSelector } from "react-redux";

import * as Yup from "yup";
import { initialValues } from "./const";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../LoginForm/FormProvider";
import Typography from "@mui/material/node/Typography";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AddClientFiles } from "../../../Api/api";

const FileUpload = ({ upload, setUpload, clientLocation }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [yearName, setYearName] = useState(new Date());
  const [name, setName] = useState("");
  const [monthName, setMonthName] = useState(new Date());
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(true);
  const formData = new FormData();
  const filesSchema = Yup.object().shape({
    location: Yup.mixed().test(
      "is-non-empty",
      `Location is Required`,
      function (value) {
        if (typeof value === "string") {
          return !!value.trim(); // Check if the trimmed string is not empty
        } else if (Array.isArray(value)) {
          return value.length > 0; // Check if it's a non-empty array
        } else {
          return false; // Any other type is invalid
        }
      }
    ),
    fileType: Yup.mixed().test(
      "is-non-empty",
      `File Type is Required`,
      function (value) {
        if (typeof value === "string") {
          return !!value.trim(); // Check if the trimmed string is not empty
        } else if (Array.isArray(value)) {
          return value.length > 0; // Check if it's a non-empty array
        } else {
          return false; // Any other type is invalid
        }
      }
    ),
  });

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
    setName(event.target.files[0].name);
    setFileError(false);
  };

  const onSubmit = async (values) => {
    if (fileError) {
      alert(`file is required`);
    }  else {
      formData.append("file", file);
      formData.append("clientId", userInfo.id);
      formData.append("location", values.location);
      formData.append("fileType", values.fileType);
      formData.append("month", monthName.getMonth());
      formData.append("year", yearName.getFullYear());
      formData.append("name", name);
      // console.log(formData.entries().next().value[1].name);

      await AddClientFiles(formData);
      alert(`${name} is uploaded`);
    }
    // window.location.reload();
    //   setTimeout(function(){
    //     window.location.reload();
    //  }, 2000);
  };
  const methods = useForm({
    resolver: yupResolver(filesSchema),
    // data,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  return (
    <Paper elevation={0} sx={{ml:5}} >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            mb: 5,
            display: "flex",
            justifyContent: "center",
            fontSize: 28,
            fontWeight: 700,
          }}
        >
          Add Client Payment Files
        </Typography>
        <Stack direction="row" alignItems="center" mt={5} spacing={2}>
          <Controller
            name="location"
            control={methods.control}
            defaultValue={[]}
            rules={{ required: true }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                options={clientLocation?.map((item) => item.location)}
                getOptionLabel={(option) => option}
                sx={{
                  width: 180,
                }}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    name="location"
                    helperText={error?.message}
                    error={error}
                    {...params}
                    label={"Location"}
                  />
                )}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <Controller
                name="year"
                control={methods.control}
                defaultValue={[]}
                rules={{ required: true }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    onChange={(newValue) => {
                      setYearName(newValue.$d);
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    label={"year"}
                    openTo="year"
                    views={["year"]}
                    disableFuture
                  />
                )}
              />
              <Controller
                name="month"
                control={methods.control}
                defaultValue={[]}
                rules={{ required: true }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    onChange={(newValue) => {
                      setMonthName(newValue.$d);
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    label={"month"}
                    openTo="month"
                    views={["month"]}
                    disableFuture
                  />
                )}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Controller
            name="fileType"
            control={methods.control}
            defaultValue={[]}
            rules={{ required: true }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                options={["PF", "ESIC", "Muster Roll", "Wage Register"].map(
                  (item) => item
                )}
                getOptionLabel={(option) => option}
                sx={{
                  width: 180,
                }}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    name="fileType"
                    helperText={error?.message}
                    error={error}
                    {...params}
                    label={"File Type"}
                  />
                )}
              />
            )}
          />
        </Stack>
       
        <Stack direction="row" alignItems="center" mt={5} spacing={2}>
          <label htmlFor="upload-file">
            <Button variant="contained" component="span">
              Upload Client Files
            </Button>
            <input
              id="upload-file"
              hidden
              accept="file/*"
              type="file"
              name="file"
              onChange={handleFileUpload}
            />
          </label>
          {name!=="" && <Typography>{name}</Typography>}
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </FormProvider>
    </Paper>
  );
};

export default FileUpload;
