import React, { useState } from "react";
import * as Yup from "yup";
import FormProvider from "../../Form/LoginForm/FormProvider";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Paper, Stack, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddClientData, AddLocation } from "../../../Api/api";
import Typography from "@mui/material/node/Typography";

const AddClient = () => {
  const clientSchema = Yup.object().shape({
    title: Yup.string().required(`Company Name is Required `),
    clientName: Yup.string().required(`Client Name is Required `),
    userName: Yup.string().required(`User Name is Required `),
    password: Yup.string().required(`password is Required `),
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState();
  const formData=new FormData()

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(event.target.files[0])
    console.log(event.target.files);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = async(values) => {
    if (imageUrl) {
      formData.append("file",file);
      formData.append("title",values.title);
      formData.append("name",values.clientName);
      formData.append("userName",values.userName);
      formData.append("password",values.password);
      await AddClientData(formData)
      // .then((res) => {
      //   if (res) {
      //     const data={
      //       clientId:res.id,
      //       location:values.location
      //     }
      //     AddLocation(data)
      //   }
      // })
    } else {
      console.log("bye");
    }
  };
  const methods = useForm({
    resolver: yupResolver(clientSchema),
    // data,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  return (
    <Paper
      elevation={2}
      sx={{
        py: 10,
        mt: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{mb:5, display:"flex",justifyContent:"center",fontSize:28,fontWeight:700}} >Add Client</Typography>
        <Controller
          name="title"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              sx={{
                background: "#fcfcfc",
                "& .Mui-focused": {
                  backgroundColor: "#fff",
                },
              }}
              error={!!error}
              helperText={error?.message}
              label="Client Company Name"
            />
          )}
        />
        <Controller
          name="clientName"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              sx={{
                background: "#fcfcfc",
                mt: 5,
                "& .Mui-focused": {
                  backgroundColor: "#fff",
                },
              }}
              error={!!error}
              helperText={error?.message}
              label="Client Name"
            />
          )}
        />
        <Controller
          name="userName"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              sx={{
                background: "#fcfcfc",
                mt: 5,
                "& .Mui-focused": {
                  backgroundColor: "#fff",
                },
              }}
              error={!!error}
              helperText={error?.message}
              label="User Name"
            />
          )}
        />
        <Controller
          name="password"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              sx={{
                background: "#fcfcfc",
                mt: 5,
                "& .Mui-focused": {
                  backgroundColor: "#fff",
                },
              }}
              error={!!error}
              helperText={error?.message}
              label="password"
            />
          )}
        />
          {/* <Controller
          name="location"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              sx={{
                background: "#fcfcfc",
                mt: 5,
                "& .Mui-focused": {
                  backgroundColor: "#fff",
                },
              }}
              error={!!error}
              helperText={error?.message}
              label="Client Location"
            />
          )}
        /> */}
        <Stack direction="row" alignItems="center" mt={5} spacing={2}>
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Client Logo
            </Button>
            <input
              id="upload-image"
              hidden
              accept="image/*"
              type="file"
              name="file"
              onChange={handleFileUpload}
            />
          </label>
          {imageUrl && <img src={imageUrl} alt="Uploaded Image" height="200" />}
          {imageUrl &&  <Button variant="contained" onClick={()=>{setImageUrl(null)}} >
              Remove Image
            </Button>}
        </Stack>
        {/* <input type="file" id="file" name="file" accept="application/*" onChange={handleChange} /> */}

        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </FormProvider>
    </Paper>
  );
};

export default AddClient;
