import axios from "axios";

// export const BaseUrl = 'https://ssos-compliance.onrender.com'
// export const BaseUrl = "http://localhost:4001";
export const BaseUrl = "https://ssos-backend-production.up.railway.app";
// take end point
const END_POINTS = {
  ADD_CLIENT: () => "/add-client",
  GET_CLIENTS: () => "/get-clients",
  ADD_LOCATION: () => "/add-clientLocation",
  GET_LOCATIONS: () => "/get-clientLocation",
  ADD_DETAIL: () => "/add-clientDetail",
  GET_DETAILS: () => "/get-clientDetail",
  UPDATE_DETAIL:()=>"/clientDetail",
  ADD_CLIENTFILES: () => "/add-files",
  GET_CLIENTFILES: () => "/get-filesBySearch",
};
const URL = (End_Point) => `${BaseUrl}${End_Point}`;

export const AddClientData = async (formData) => {
  const {data} = await axios.post(URL(END_POINTS.ADD_CLIENT()), formData);
  return data
};

export const GetClient = async () => {
  const { data } = await axios.get(URL(END_POINTS.GET_CLIENTS()));

  return data;
};

export const AddLocation = async (clientBody) => {
  await axios.post(URL(END_POINTS.ADD_LOCATION()), { ...clientBody });
};

export const GetLocation = async (clientId) => {
  const { data } = await axios.get(URL(END_POINTS.GET_LOCATIONS()), {
    params: { clientId },
  });
  return data;
};
export const AddClientDetail = async (clientDetailBody) => {
  await axios.post(URL(END_POINTS.ADD_DETAIL()), {
    ...clientDetailBody,
  });

};
export const GetClientDetail = async (clientId, locationId) => {
  const { data } = await axios.get(URL(END_POINTS.GET_DETAILS()), {
    params: { clientId: clientId, clientLocationId: locationId },
  });

  return data;
};
export const updateClientDetail= async(id,body) => {
  const { data } = await axios.patch(URL(`${END_POINTS.UPDATE_DETAIL()}/${id}`), {
    ...body
  });

  return data;

}
export const AddClientFiles = async (formData) => {
  await axios.post(URL(END_POINTS.ADD_CLIENTFILES()), formData);
};

export const GetClientFiles = async (params) => {
  const { data } = await axios.get(URL(END_POINTS.GET_CLIENTFILES()),{params:params});

  return data;
};
