import React from "react";
import { Button, Container, Paper, Typography } from "@mui/material";
import ClientList from "../ClientList/ClientList";
import { useNavigate } from "react-router-dom";

const OurClient = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/", { replace: true });
  };

  return (
    <Container>
      <Paper elevation={0} sx={{ marginBottom: 2 }}>
        <Button variant="contained" onClick={handleBack}>
          Back
        </Button>
      </Paper>
      <Paper elevation={0} sx={{ paddingY: 10, textAlign: "center" }}>
        <Typography variant="h2" fontWeight="bold" color="#0C66C6">
          Our Client
        </Typography>
        <ClientList />
      </Paper>
    </Container>
  );
};

export default OurClient;
