import React from "react";
import HomePage from "../Home/HomePage";
import { Button, Container } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MainSite = () => {
  return (
    <Container>
      <Button variant="contained" href="https://ssos.co.in/">
        {" "}
        <ArrowBackIcon sx={{ mr: 2 }} />
        Back to Main Site{" "}
      </Button>
      <HomePage />
    </Container>
  );
};

export default MainSite;
