import { Route, Routes } from "react-router-dom";

import ClientDetail from "./components/Component/ClientDetail/ClientDetail";
import Login from "./components/Form/LoginForm/Login";
import Dashboard from "./components/Page/Dashboard/Dashboard";
import MainSite from "./components/Page/MainSite/MainSite";
import AddClient from "./components/Component/AddClient/AddClient";
import OurClient from "./components/Component/OurClient/OurClient";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainSite />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="addclients" element={<AddClient />} />
      </Route>
      <Route path="/clientDetail" element={<ClientDetail />} />
      <Route path="/ourClient" element={<OurClient />} />
    </Routes>
  );
};

export default App;
