// import { Button, Modal } from "flowbite-react";
import React from "react";
import { url } from "../../Utils/mockData";
import { Box, Button, Modal } from "@mui/material";
import Typography from "@mui/material/node/Typography";

const ViewModal = ({ open, setOpen, file, fileName }) => {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(`${url}/${file}`).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${fileName}`;
        alink.click();
        setOpen(false)
      });
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Click on below button to download PDF file
        </Typography>
        <Button onClick={onButtonClick}> Download PDF  </Button>

          
      </Box>
    </Modal>
  );
};

export default ViewModal;
