import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: { cardState: "Our Client" },
  reducers: {
    addCardState(state, action) {
      state.cardState = action.payload;
    },
    removeCardState(state) {
      state.cardState = "Our Client";
    },
  },
});
export const dashboardCardActions = dashboardSlice.actions;
export default dashboardSlice;
