import axios from "axios";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { loginActions } from "../../../Store/Auth-Slice";
import { BaseUrl, GetClient } from "../../../Api/api";
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
// import { clientData, url } from '../../../Utils/mockData'

const ClientList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    const clientfetching = async () => {
      const data = await GetClient();
      setData(data);
    };
    clientfetching();
  }, []);

  return (
    <Box sx={{}} display={"flex"} flexDirection={"row"} gap={4} flexWrap={"wrap"} >

        {data.map((d) => (
          <Card sx={{ maxWidth: 345 }}  >
          <CardActionArea   
              onClick={() => {
                dispatch(loginActions.user({ userInfo: d }));
                navigate("/login", { replace: true });
              }}
          >
            <CardMedia
              component="img"
              height="240"
              width={140}
              image={`${BaseUrl}/${d.imsrc}`}
              alt="clientImage"
              sx={{objectFit:"contain"}}
            >
              
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h6" fontSize={22} component="div">
              {d.title}
              </Typography>
              
            </CardContent>
          </CardActionArea>
        </Card>
          // <div className="w-full  p-0">
          //   <Card
          //     imgAlt="Meaningful alt  text for an image that is not purely decorative"
          //     className="h-96"
          //     onClick={() => {
          //       dispatch(loginActions.user({ userInfo: d.name, userId: d.id }));
          //       navigate("/login", { replace: true });
          //     }}
          //     // style={{ height: "400px",  marginBottom: "50px" }}
          //     imgSrc={`${BaseUrl}/${d.imsrc}`}
          //   >
          //     <h5 className="lg:text-md md:text-md text-sm font-bold   text-sky-600 dark:text-white">
          //       {d.title}
          //     </h5>
          //   </Card>
          // </div>
        ))}
        </Box>
  );
};

export default ClientList;
