import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetLocation } from "../../../Api/api";
import { Autocomplete, Container, Paper, TextField, Typography } from "@mui/material";
import ClientDetail from "../ClientDetail/ClientDetail";
import CompanyFile from "../CompanyFile/CompanyFile";
import FileUpload from "../../Form/FileUpload/FileUpload";

const DashboardData = ({ isAdminLogged }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [upload, setUpload] = useState("");
  const [clientLocation, setClientLocation] = useState([]);
  useEffect(() => {}, [upload]);
  useEffect(() => {
    const locationfetching = async () => {
      const data = await GetLocation(userInfo.id);
      setClientLocation(data);
    };
    locationfetching();
  }, []);
  return (
    <Paper elevation={2} sx={{ overflow: "hidden" }}>
      <Autocomplete
        inputValue={location}
        onInputChange={(event, newInputValue) => {
          setLocation(newInputValue);
          clientLocation?.map((item) => {
            if (item.location === newInputValue) {
              setLocationId(item.id);
            }
          });
          if (newInputValue==="") {
            setLocationId(null)
          }
        }}
        id="controllable-states-demo"
        options={clientLocation?.map((item) => item.location)}
        sx={{ width: 300, p: 5 }}
        renderInput={(params) => <TextField {...params} label="Location" />}
      />
      <Paper elevation={0} sx={{p:8 }} >
      <Typography fontSize={28} fontWeight={900} textAlign={"center"} >
        Client Detail
      </Typography>
      {locationId !== null && (
        <ClientDetail locationId={locationId} clientId={userInfo.id} />
      )}
      </Paper>
      {location !== "" && <CompanyFile location={location} />}
      {isAdminLogged && (
        <FileUpload
          upload={upload}
          setUpload={setUpload}
          clientLocation={clientLocation}
        />
      )}
    </Paper>
  );
};

export default DashboardData;
