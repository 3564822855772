import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import SideBar from "../../Component/SideBar/SideBar";
import Navbar from "../../Navbar/Navbar";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import AddClient from "../../Component/AddClient/AddClient";
import DashboardData from "../../Component/Dasboard/DashboardData";

const Dashboard = () => {
  const { isAdminLogged, userInfo, cardState } = useSelector((state) => ({
    isAdminLogged: state.admin.isAdminLogged,
    userInfo: state.auth.userInfo,
    cardState: state.dashboard.cardState
  }));

  const [menuData, setMenuData] = useState(
    useMemo(() => (cardState === "Our Client" ? "Dashboard" : "AddClient"), [cardState])
  );

  const drawerWidth = 120;

  return (
    <React.Fragment>
      <Navbar drawerWidth={drawerWidth} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <SideBar drawerWidth={drawerWidth} setMenuData={setMenuData} />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default" }}
        >
          <Toolbar />
          {menuData === "Dashboard" && userInfo && (
            <DashboardData isAdminLogged={isAdminLogged} />
          )}
          {menuData === "AddClient" && <AddClient />}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
