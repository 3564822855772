import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./Admin-slice";
import authSlice from "./Auth-Slice";
import dashboardSlice from "./Dashboard-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    admin: adminSlice.reducer,
    dashboard: dashboardSlice.reducer,
  },
});
export default store;
