import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../Form/LoginForm/FormProvider";

const Filtering = ({ handleSearch }) => {
  const [year, setYear] = useState(new Date());
  const [searchYear, setSearchYear] = useState(null);
  const [searchMonth, setSearchMonth] = useState(null);
  const [month, setMonth] = useState(new Date());

  const [fileType, setFileType] = useState("");
  const filesTypeSchema = Yup.object().shape({});
  const onSubmit = (values) => {
    // console.log(values.year.$d.getFullYear());
    handleSearch(values.month?.$d.getMonth(), values.year?.$d.getFullYear(), values.fileType);
  };
  const handleRemove = () => {

    setSearchMonth(null);
    setSearchYear(null);
    setFileType("");
    
    handleSearch(searchMonth, searchYear, fileType);
  };
  const methods = useForm({
    resolver: yupResolver(filesTypeSchema),
    //  defaultValue,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" alignItems="center" mt={5} ml={5} spacing={2}>
        <Controller
          name="year"
          defaultValue={searchYear}
          control={methods.control}
          render={({ field: { onChange, ...restField } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(event) => {
                  onChange(event);
                  console.log(event);
                  // setreqDate(event);
                }}
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                label={"year"}
                openTo="year"
                views={["year"]}
                disableFuture
                renderInput={(params) => <TextField {...params} />}
                {...restField}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="month"
          defaultValue={searchMonth}
          control={methods.control}
          render={({ field: { onChange, ...restField } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(event) => {
                  onChange(event);
                  console.log(event);
                }}
                clearable
                clearText="Clear"
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                label={"month"}
                openTo="month"
                views={["month"]}
                value={searchMonth}
                renderInput={(params) => <TextField {...params} />}
                {...restField}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="fileType"
          control={methods.control}
          defaultValue={[]}
          rules={{ required: true }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Autocomplete
              disablePortal
              options={["PF", "ESIC", "Muster Roll", "Wage Register"].map(
                (item) => item
              )}
              getOptionLabel={(option) => option}
              sx={{
                width: 180,
              }}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, value) => {
                field.onChange(value);
              }}
              renderInput={(params) => (
                <TextField name="fileType" {...params} label={"File Type"} />
              )}
            />
          )}
        />
        <Button type="submit">Filter</Button>
        {/* <Button onClick={handleRemove}> Remove Filter</Button> */}
      </Stack>
    </FormProvider>
  );
};

export default Filtering;
