import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { url } from "../../../Utils/mockData";
import ViewModal from "../../Modal/VeiwModal";

import Filtering from "../Filtering/Filtering";
import {
    Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GetClientFiles } from "../../../Api/api";

const CompanyFile = ({ location }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [monthName, setMonthName] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [data, setdata] = useState([]);
  const [filePath, setFilePath] = useState("");
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    const fetching = async () => {
      setdata([]);
      // console.log("hi" ,data);
      //     console.log(data);
      const data  = await GetClientFiles({location:location,clientId:userInfo.id})
      setdata(data);
    };

    fetching();
  }, [location]);
  const handleSearch = async (month, year, fileType) => {
    const  data  = await GetClientFiles({location:location,clientId:userInfo.id,fileType,month,year})
    setdata(data);
  };

  return (
    <Paper elevation={0} >
      <Filtering handleSearch={handleSearch} />
      <TableContainer component={Paper}>
        <Table
          sx={{ maxWidth: 1200, border: 1, m: 5 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: 1 }}>Date</TableCell>
              <TableCell sx={{ border: 1 }} align="left">
              Location
              </TableCell>
              <TableCell sx={{ border: 1 }} align="left">
              File Name
              </TableCell>
              <TableCell sx={{ border: 1 }} align="left">
              File Type
              </TableCell>
              <TableCell sx={{ border: 1 }} align="left">
              Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
              >
                <TableCell component="th" scope="row">
                {monthName[row.month]}-{row.year}
                </TableCell>
                <TableCell sx={{ borderLeft: 1 }} align="left">
                {row.location}
                </TableCell>
                <TableCell sx={{ borderLeft: 1 }} align="left">
                {row.name}
                </TableCell>
                <TableCell sx={{ borderLeft: 1 }} align="left">
                {row.fileType}
                </TableCell>
                <TableCell sx={{ borderLeft: 1 }} align="left">
                <Button onClick={()=>{setOpen(true);setFilePath(row.file);setFileName(row.name)}} >Download</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ViewModal open={open} fileName={fileName} file={filePath} setOpen={setOpen} />
    </Paper>
  );
};

export default CompanyFile;
