import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginActions } from "../../Store/Auth-Slice";
import { adminLoginActions } from "../../Store/Admin-slice";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { dashboardCardActions } from "../../Store/Dashboard-slice";

const Navbar = ({drawerWidth}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` ,backgroundColor:"white"}}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
        <Button
            color="primary"
            onClick={() => {
              dispatch(loginActions.logout());
              dispatch(adminLoginActions.logout());
              dispatch(dashboardCardActions.removeCardState());
              navigate("/", { replace: true });
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
