import React, { useEffect, useMemo, useState } from "react";
import { GetClientDetail, updateClientDetail } from "../../../Api/api";
import { data } from "./const";
import { Controller, Form, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FormProvider from "../../Form/LoginForm/FormProvider";
import { useSelector } from "react-redux";

const ClientDetail = ({ locationId, clientId }) => {
  const isAdminLogged = useSelector((state) => state.admin.isAdminLogged);
  const [clientDetail, setClientDetail] = useState([]);
  const [inputDisabled, setInputDisabled] = useState([]);
  useEffect(() => {
    const detailfetching = async () => {
      const data = await GetClientDetail(clientId, locationId);
      setClientDetail(data);
    };
    detailfetching();
  }, [locationId, clientId]);
  // const clientDetsailSchema = Yup.object().shape({
  //   particulars_detail: Yup.string().required(
  //     `Particulars_Details is Required `
  //   ),
  //   description: Yup.string().required(`Description is Required `),
  // });
  const onSubmit = async (methods, id) => {
    const data = {
      heading: methods.getValues()[id + 1].particulars_detail,
      detail: methods.getValues()[id + 1].description,
    };
    const response = await updateClientDetail(id, data);
    setInputDisabled(inputDisabled.filter((data) => data.id !== id));
  };
  // const onClick = (methods,id) => {
  //   console.log(methods, "hi",id);
  // };
  const methods = useForm({
    // resolver: yupResolver(clientDetsailSchema),
    data,
  });
  const clientTableData = useMemo(() => {
    const clientData = clientDetail?.map((value) => {
      // setInputDisabled({`${value.id}_disabled`:true})
      const findObject = inputDisabled.find((obj) => {
        return obj.id === value.id;
      });
      return {
        id: value.id,
        heading: value.heading,
        detail: value.detail,
        disabled: findObject ? findObject.disabled : true,
      };
    });

    return clientData ? clientData : [];
  }, [clientDetail, inputDisabled]);
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const handleClose = (id, disabled) => {};
  return (
    <React.Fragment>
      <TableContainer component={Paper} elevation={0}>
        <FormProvider methods={methods}>
          <Table sx={{ border: 1, mt: 3 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Particulars/Details</TableCell>
                <TableCell sx={{ borderLeft: 1, borderRight: 1 }} align="left">
                  Description
                </TableCell>
                {isAdminLogged && <TableCell width={"10%"}>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientTableData?.map(({ id, disabled, heading, detail }) => (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                >
                  <TableCell component="th" scope="row">
                    <Controller
                      name={`${id + 1}.particulars_detail`}
                      control={methods.control}
                      defaultValue={heading}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          // name={`${id + 1}.particulars_detail`}
                          fullWidth
                          disabled={disabled}
                          // defaultValue={heading}
                          sx={{
                            background: "#fcfcfc",
                            "& .MuiInputBase-root.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                              fontWeight: "bold",
                              "& fieldset": { border: "none" },
                            },
                            "& .Mui-focused": {
                              backgroundColor: "#fff",
                              border: "none",
                            },
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ borderLeft: 1, borderRight: 1 }}
                    align="left"
                  >
                    <Controller
                      name={`${id + 1}.description`}
                      control={methods.control}
                      defaultValue={detail}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          // name={`${id + 1}.description`}
                          disabled={disabled}
                          fullWidth
                          // defaultValue={detail}
                          sx={{
                            background: "#fcfcfc",
                            "& .MuiInputBase-root.Mui-disabled": {
                              WebkitTextFillColor: "#000000",
                              fontWeight: "bold",
                              "& fieldset": { border: "none" },
                            },
                            "& .Mui-focused": {
                              backgroundColor: "#fff",
                              border: "none",
                            },
                          }}
                          // error={!!error}
                          // helperText={error?.message}
                        />
                      )}
                    />
                  </TableCell>
                  {isAdminLogged && (
                    <TableCell>
                      {disabled && (
                        <Button
                          onClick={() => {
                            setInputDisabled((inputDisabled) => [
                              ...inputDisabled,
                              { id: id, disabled: !disabled },
                            ]);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {!disabled && (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          {" "}
                          <Button
                            onClick={() => {
                              onSubmit(methods, id);
                            }}
                          >
                            {" "}
                            <DoneIcon color="success" />{" "}
                          </Button>
                          <Button
                            onClick={() => {
                              setInputDisabled(
                                inputDisabled.filter((data) => data.id !== id)
                              );
                              // console.log(methods.setValue());
                              // methods.reset();
                            }}
                          >
                            {" "}
                            <CloseIcon color="warning" />
                          </Button>
                        </Box>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </FormProvider>
      </TableContainer>
    </React.Fragment>
  );
};

export default ClientDetail;
