import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";

const SideBar = React.memo(({ drawerWidth, setMenuData }) => {
  const cardState = useSelector((state) => state.dashboard.cardState);

  const sidebarItems = useMemo(() => {
    const renderListItem = (text, menuDataValue) => (
      <ListItem
        key={text}
        disablePadding
        onClick={() => setMenuData(menuDataValue)}
      >
        <ListItemButton>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    );

    switch (cardState) {
      case "Our Client":
        return renderListItem("Dashboard", "Dashboard");
      case "Add Client":
        return renderListItem("Add Client", "AddClient");
      default:
        return null;
    }
  }, [cardState, setMenuData]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>{sidebarItems}</List>
    </Drawer>
  );
});

export default SideBar;
